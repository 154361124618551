@font-face {
  font-family: "Inter-Regular";
  src: local("Inter"), url(../fonts/Inter-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-Medium";
  src: local("Inter"), url(../fonts/Inter-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-Bold";
  src: local("Inter"), url(../fonts/Inter-Bold.ttf) format("truetype");
}
